<template>
  <div class="container">
    <Box :title="$t('90e460a')" style="margin-bottom: 0">
      <div v-loading="loading">
        <div v-for="item in list" :key="item.serviceNo" class="edu-item">
          <div
            class="cover"
            :style="{ backgroundImage: `url(${item.picture})` }"
          ></div>
          <div class="cont">
            <div class="title">
              <h5>
                {{ item.title | textFilter }}
              </h5>
              <span class="tag" :class="tagCls[item.status]">{{
                $t(status2i18n[item.status]) | textFilter
              }}</span>
            </div>
            <ul class="desc">
              <li>
                <i class="el-icon-time"></i>
                <span>{{ $t('9cf548f') }}：{{ item.date }} {{ item.startTime }}-{{
                  item.endTime
                }}</span>
              </li>
              <li>
                <i class="el-icon-location-outline"></i>
                <span>{{ $t('6a7552b') }}：{{ item.address | textFilter }}</span>
              </li>
              <li>
                <i class="el-icon-user"></i>
                <span>{{ $t('e192617') }}：{{ item.organizer | textFilter }}</span>
              </li>
            </ul>
            <el-button class="btn" @click="jump(item.id)">{{ $t('ad6ab8a') }}</el-button>
          </div>
        </div>
        <div v-if="!list.length" class="empty">暂无数据</div>
      </div>
      <div class="pager">
        <el-pagination
          :current-page="query.page"
          :page-size="query.size"
          layout="total, prev, pager, next, jumper"
          :total="total"
          @current-change="getList"
        />
      </div>
    </Box>
  </div>
</template>

<script>
import Box from '@/components/Box'
import Api from '@/api/information'

export default {
  components: {
    Box,
  },
  data() {
    return {
      loading: false,
      list: [],
      total: 0,
      query: {
        page: 1,
        size: 5,
      },
      tagCls: {
        已结束: 'tag-after',
        未开始: 'tag-before',
        进行中: 'tag-doing',
      },
      status2i18n: {
        "进行中": '6101ef7',
        "未开始": '112a098',
        "已结束": '692e24c',
      }
    }
  },
  mounted() {
    this.getList(1)
  },
  methods: {
    getList(page) {
      this.loading = true

      Api.getEduList({ ...this.query, page })
        .then((res) => {
          this.query.page = page
          this.list = res.content
          this.total = res.page.total
        })
        .catch((err) => {})
        .then((res) => {
          this.loading = false
        })
    },
    jump(id) {
      this.$router.push({
        path: '/information/trainDetail',
        query: { id },
      })
    },
  },
}
</script>

<style lang="less" scoped>
.empty {
  padding: 30px;
  color: #999999;
  font-size: 14px;
  text-align: center;
}

.pager {
  padding: 20px 20px 0;
  text-align: right;
}

.edu-item {
  display: flex;
  padding: 40px 0;
  border-bottom: 1px dashed #e8e8e8;

  &:first-child {
    padding-top: 10px;
  }

  .cover {
    width: 320px;
    height: 200px;
    margin-right: 30px;
    border-radius: 4px;
    background: #efefef no-repeat center;
    background-size: cover;
  }

  .cont {
    position: relative;
    min-width: 0;
    flex: 1;
  }

  .title {
    height: 70px;
    display: flex;
    align-items: center;

    h5 {
      font-size: 18px;
      line-height: 32px;
      color: #333333;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .tag {
      display: block;
      text-align: center;
      margin-left: 8px;
      min-width: 100px;
      padding: 0 10px;
      height: 32px;
      line-height: 32px;
      color: #999999;
      background: #ededed;
      font-weight: bold;

      &-doing {
        color: #27c360;
        background-color: #e4f8eb;
      }

      &-after {
        color: #999999;
        background: #ededed;
      }

      &-before {
        color: #faad14;
        background: #fef2d9;
      }
    }
  }

  .desc {
    font-size: 14px;
    color: #666666;
    line-height: 24px;

    i {
      font-size: 16px;
      margin-right: 10px;
      color: #c3c3c3;
    }

    li {
      padding-bottom: 16px;
    }
  }

  .btn {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    min-width: 120px;
    height: 40px;
    border-color: #00a4ff;
    color: #00a4ff;
  }
}
</style>
